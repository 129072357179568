/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container, Row } from 'react-grid-system';
// Style
import './index.scss';
// APIs
import { API_PARTNERS, API_UPLOADS } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PartnersSpecialities({ category: preCategory }) {
  // localization :
  const { t } = useTranslation();
  const { language } = useI18next();
  const { title, segments } = t('partners', { returnObjects: true });

  /* ******************************** CONSTANTS ******************************* */
  let currentPartners = language === 'fr' ? 'Tous' : 'All';
  if (preCategory !== undefined) {
    currentPartners = preCategory;
  }

  let preIndex = 0;
  if (preCategory !== undefined) {
    segments.forEach(({ name }, index) => {
      if (name === preCategory) {
        preIndex = index;
      }
    });
  }
  /* ********************************** HOOKS ********************************* */
  // states:
  const [partners, setPartners] = useState(currentPartners);
  const [activeButton, setActiveButton] = useState(preIndex);
  const [partnersList1, setPartnersList1] = useState();

  /* --------------------------------- HELPERS -------------------------------- */
  async function getPartners() {
    const { data } = await axios(API_PARTNERS(language));
    setPartnersList1(data);
  }
  // Effect
  useEffect(() => {
    getPartners();
  }, []);

  /* ***************************** RENDER HELPERS ***************************** */
  const specialities = segments.map(({ id, name }, index) => {
    return (
      <button
        key={id}
        type="button"
        onClick={(e) => {
          setPartners(e.currentTarget.textContent.toString());
          setActiveButton(index);
        }}
        className={`${activeButton === index ? 'active' : ''}`}
      >
        {name}
      </button>
    );
  });
  // filter partners images by category
  const segmentSelected = partnersList1?.data?.filter(
    ({ attributes: { category } }) => category === partners,
  );
  // Render partners
  // eslint-disable-next-line operator-linebreak
  const finalList =
    partners === 'All' || partners === 'Tous'
      ? partnersList1?.data
      : segmentSelected;
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  const partnersImages = finalList?.map(
    ({
      attributes: {
        images: { data },
      },
      id,
    }) => {
      return (
        <img
          key={id}
          src={API_UPLOADS(data[0]?.attributes?.url)}
          alt="partner"
        />
      );
    },
  );

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="partners-specialities scroll-reveal" fluid>
      <h1>{title}</h1>
      <Row align="center" justify="center" className="segments-container">
        {specialities}
      </Row>
      <div className="images-container ">{partnersImages}</div>
    </Container>
  );
}

PartnersSpecialities.propTypes = {
  category: PropTypes.string,
};

PartnersSpecialities.defaultProps = {
  category: undefined,
};

export default PartnersSpecialities;
