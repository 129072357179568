/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// Lib UI components
import PartnersSpecialities from '../page-components/Partners/PartnersSpecialities';
// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Style
import '../page-styles/Partners.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Partners({ location }) {
  /* ********************************** HOOKS ********************************* */
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Partners" />
      <div className="container">
        <PartnersSpecialities category={location?.state?.category} />
      </div>
    </PageLayout>
  );
}

Partners.propTypes = {
  location: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
};

Partners.defaultProps = {
  location: undefined,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Partners", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Partners;
